import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchNotes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/notes', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCourseNotes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/notes', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchNote(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/notes/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateNote(ctx, {id, data}) {
      return new Promise((resolve, reject) => {
        axios
            .put('/notes/' + id, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    addNote(ctx, noteData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/notes', { noteData })
          .then(response => resolve(response))
          .catch(error => reject(error))

      })
    },
    deleteNote(ctx, noteId) {
      return new Promise((resolve, reject) => {
        axios
            .delete('/notes/' + noteId)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
  },
}
